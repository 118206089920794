const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

// export const getHeaders = () => {
//   const bearer = localStorage.getItem("accessTokenChecklist");
//   if (typeof bearer !== "string") throw new Error("missing bearer token");
//   return {
//     ...HEADERS,
//     Authorization: `Bearer ${bearer}`,
//   };
// };
export const getHeaders = (token = true) => {
  if (token === true) {
    const bearer = localStorage.getItem("accessTokenChecklist");
    if (typeof bearer !== "string") throw new Error("missing bearer token");
    return {
      ...HEADERS,
      Authorization: `Bearer ${bearer}`,
    };
  }
  if (token === false) {
    return {
      ...HEADERS,
    };
  }
};