import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import spinner from "./spinner4.svg";
// import "../../../style/style-cargando.css";
// import cargandoCard from "../../../components/cargando-card";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { countUserName, registrarUsuario } from "../../api/api-sign-up";

export default function SignUp() {
    const location = useLocation();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmarPassword, setShowConfirmarPassword] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [cargandoPage, setCargandoPage] = useState(true);
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        nota: '',
        usuario: '',
        password: '',
        confirmarPassword: '',
    });

    const charLimits = {
        nombre: 36,
        apellido: 36,
        nota: 256,
        usuario: 36,
        password: 128,
        confirmarPassword: 128,
    };

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            // console.log("data");
            // console.log(data);
            if (data !== undefined && data !== null) {
                setFormData(data.params);
                // setTitulo(data.titulo);
            }

            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= charLimits[name]) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    };

    const validarStep2 = () => {
        try {
            let result = { error: true, message: "" };
            let message = "";
            if (formData.usuario.trim().length === 0) {
                message = message + "Debe ingresar usuario" + "\n";
            }
            if (formData.password.trim().length === 0) {
                message = message + "Debe ingresar password" + '\n';
            }
            if (formData.confirmarPassword.trim().length === 0) {
                message = message + "Debe ingresar confirmar password" + '\n';
            }
            if (message.length > 0) {
                result.error = true;
                result.message = message;
            } else {
                result.error = false;
            }
            return result;
        } catch (err) {
            return { error: true, message: err };
        }
    };

    const createAccount = async (e) => {
        // console.log('createAccount');
        e.preventDefault();
        // lógica para guardar los cambios
        // console.log('formData');
        // console.log(formData);
        const isValid = validarStep2();
        if (isValid.error === true) {
            alert(isValid.message);
            return;
        }
        if (formData.password !== formData.confirmarPassword) {
            setPasswordError("Passwords no coinciden");
            return;
        }
        // return;
        let objEnviar = {};
        objEnviar.nombre = formData.nombre;
        objEnviar.apellido = formData.apellido;
        objEnviar.nota = formData.nota;
        objEnviar.usuario = formData.usuario;
        objEnviar.password = formData.password;
        objEnviar.confirmarPassword = formData.confirmarPassword;
        // console.log('objEnviar');
        // console.log(objEnviar);
        const validUsername = await countUserName(objEnviar);
        console.log('validUsername');
        console.log(validUsername);
        if (validUsername.error === true) {
            alert('No se pudo verificar el usuario');
            return;
        }
        if (validUsername.total > 0) {
            alert('Usuario ya existe');
            return;
        }
        // return;

        // console.log('actualizar correo');
        const response = await registrarUsuario(objEnviar);
        // console.log('modificado');
        // console.log(modificado);
        if (response.error === false) {
            alert('Se creo correctamente');
            handleAtrasClick();
        }
        if (response.error === true) {
            alert('No se pudo crear\n' + response.message);
        }

    };

    const validarStep1 = () => {
        try {
            let result = { error: true, message: "" };
            let message = "";
            if (formData.nombre.trim().length === 0) {
                message = message + "Debe ingresar Nombre" + "\n";
            }
            if (formData.apellido.trim().length === 0) {
                message = message + "Debe ingresar Apellido" + '\n';
            }
            if (message.length > 0) {
                result.error = true;
                result.message = message;
            } else {
                result.error = false;
            }
            return result;
        } catch (err) {
            return { error: true, message: err };
        }
    };

    const handleNextStep = async (e) => {
        try {
            // console.log('handleNextStep');
            // console.log(formData);
            e.preventDefault();
            const isValid = validarStep1();
            if (isValid.error === true) {
                alert(isValid.message);
                return;
            }
            if (isValid.error === false) {
                setStep(2);
            } else {
                alert(isValid.message);
            }
            setLoading(false);
        } catch (err) {
            console.log('handleNextStep err');
            console.log(err);
            setLoading(false);
        }
    };

    const render = () => {
        // console.log('render correo');
        try {
            // if (cargandoPage === true) {
            //     return cargandoCard();
            // }
            if (cargandoPage === false && formData === null) {
                return (
                    <div>
                        <label> Sin data</label>
                    </div>
                );
            }
            return (
                <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                    <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                        {step === 1 && (
                            <>
                                <h2 className="text-2xl font-bold text-center text-[#0F3457] mb-6">Registrar Usuario</h2>
                                <form onSubmit={handleNextStep}>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="nameCompany">
                                            Nombre<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="nombre"
                                            name="nombre"
                                            value={formData.nombre}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Nombre"
                                            maxLength={charLimits.nombre}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.nombre.length}/{charLimits.nombre}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="apellido">
                                            Apellido<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="apellido"
                                            name="apellido"
                                            value={formData.apellido}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Apellido"
                                            maxLength={charLimits.apellido}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.apellido.length}/{charLimits.apellido}</p>
                                    </div>

                                    <div className="mb-6">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="nota">
                                            Nota
                                        </label>
                                        <textarea
                                            type="text"
                                            id="nota"
                                            name="nota"
                                            value={formData.nota}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Enter Nota"
                                            maxLength={charLimits.nota}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.nota.length}/{charLimits.nota}</p>
                                    </div>

                                    {loading === false &&
                                        <button
                                            type="submit"
                                            className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300"
                                        >
                                            Siguiente
                                        </button>
                                    }
                                    {loading === true &&
                                        <button
                                            className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300 flex justify-center items-center"
                                        >
                                            <img className="spinner" alt="spinner" src={spinner} />
                                        </button>

                                    }
                                </form>
                                <p
                                    onClick={handleAtrasClick}
                                    className="text-center text-[#0F3457] mt-4 cursor-pointer hover:underline"
                                >
                                    Atras
                                </p>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <h2 className="text-2xl font-bold text-center text-[#0F3457] mb-6">Crear Cuenta</h2>
                                <form onSubmit={createAccount}>
                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="usuario">
                                            Usuario<span className="text-red-500">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="usuario"
                                            name="usuario"
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                            placeholder="Ingrese Usuario"
                                            maxLength={charLimits.usuario}
                                            onChange={handleChange}
                                            value={formData.usuario}
                                        />
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.usuario.length}/{charLimits.usuario}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="password">
                                            Password<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type={showPassword === false ? "password" : "text"}
                                                id="password"
                                                name="password"
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                                placeholder="Enter password"
                                                maxLength={charLimits.password}
                                                onChange={handleChange}
                                                value={formData.password}
                                            />
                                            {showPassword === true &&
                                                <EyeIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowPassword(!showPassword)}
                                                    style={{ transition: "opacity 0.3s ease-in-out", }}
                                                />
                                            }
                                            {showPassword === false &&
                                                <EyeSlashIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowPassword(!showPassword)}
                                                    style={{ transition: "opacity 0.3s ease-in-out", }}
                                                />
                                            }
                                        </div>

                                        <p className="text-sm text-gray-500 flex justify-end">{formData.password.length}/{charLimits.password}</p>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-[#0F3457] font-semibold mb-2" htmlFor="confirmarPassword">
                                            Confirmar Password<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type={showConfirmarPassword === false ? "password" : "text"}
                                                id="confirmarPassword"
                                                name="confirmarPassword"
                                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#84B044]"
                                                placeholder="Confirm password"
                                                maxLength={charLimits.confirmarPassword}
                                                onChange={handleChange}
                                                value={formData.confirmarPassword}
                                            />
                                            {showConfirmarPassword === true &&
                                                <EyeIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowConfirmarPassword(!showConfirmarPassword)} />
                                            }
                                            {showConfirmarPassword === false &&
                                                <EyeSlashIcon className="cursor-pointer text-gray-500" width={32} onClick={() => setShowConfirmarPassword(!showConfirmarPassword)} />
                                            }
                                        </div>
                                        <p className="text-sm text-gray-500 flex justify-end">{formData.confirmarPassword.length}/{charLimits.confirmarPassword}</p>
                                    </div>
                                    {passwordError.length > 0 &&
                                        <span className="text-red-500">{passwordError}</span>
                                    }
                                    <button
                                        type="submit"
                                        className="w-full bg-[#84B044] text-white font-semibold py-2 px-4 rounded-lg hover:bg-[#6f9239] transition duration-300"
                                    >
                                        Registrarse
                                    </button>
                                    <p
                                        onClick={() => { setStep(1); }}
                                        className="text-center text-[#0F3457] mt-4 cursor-pointer hover:underline"
                                    >
                                        Atras
                                    </p>
                                </form>
                            </>
                        )}
                    </div>
                </div >
            );
        } catch (err) {
            console.log('SignUp render err');
            console.log(err);
            return (
                <div>
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    }

    return render();
}
