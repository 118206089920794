import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from "./page/login/login";
import RouterPage from "./page/router";
import SignUp from "./page/login/sign-up";

const RouterMain = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/">
        <RouterPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default RouterMain;
