import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_GENERAL_CANCELAR, ESTILO_INPUT_GENERICO } from "../../../utils/const/estilos-const";
import "../../../style/style-cargando.css";
import { crearOpcionesRespuesta, modificarOpcionRespuesta } from "../../../api/configuracion";
import cargandoCard from "../../../components/cargando-card";
import SwitchComponent from "../../../components/switch";
import Select from "react-select";
import { obtenerListaCorreos } from "../../../api/correo";
import { TRUE } from "../../../utils/const/bool-const";

export default function OpcionesRespuesta() {
  const location = useLocation();
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [opcionRespuesta, setOpcionRespuesta] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [isSwitchOnEnviarCorreo, setIsSwitchOnEnviarCorreo] = useState(false);
  const [isSwitchOnTomarFoto, setIsSwitchOnTomarFoto] = useState(false);
  const [isSwitchOnObservacion, setIsSwitchOnObservacion] = useState(false);
  const [isSwitchOnFecha, setIsSwitchOnFecha] = useState(false);
  const [isSwitchOnCritico, setIsSwitchOnCritico] = useState(false);
  const [isSwitchManejoDefensivo, setIsSwitchManejoDefensivo] = useState(false);
  const [isSwitchOpcionCorrectaManejoDefensivo, setIsSwitchOpcionCorrectaManejoDefensivo] = useState(false);
  const [listaCorreos, setListaCorreos] = useState([]);
  const [correosSelected, setCorreosSelected] = useState(null);

  useEffect(() => {
    // console.log("entro a Correos");
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      console.log("data");
      console.log(data);
      if (data !== undefined && data !== null) {
        setOpcionRespuesta(data.params);
        setTitulo(data.titulo);
        if (data.params.enviarCorreo === TRUE) {
          setIsSwitchOnEnviarCorreo(true);
        }
        if (data.params.tomarFoto === TRUE) {
          setIsSwitchOnTomarFoto(true);
        }
        if (data.params.pedirFecha === TRUE) {
          setIsSwitchOnFecha(true);
        }
        if (data.params.correos !== null) {
          setCorreosSelected(JSON.parse(data.params.correos));
        }
        if (data.params.notaObservacion !== null) {
          setIsSwitchOnObservacion(JSON.parse(data.params.notaObservacion));
        }
        if (data.params.critico === TRUE) {
          setIsSwitchOnCritico(true);
        }
        if (data.params.manejoDefensivo === TRUE) {
          setIsSwitchManejoDefensivo(true);
        }
        if (data.params.opcionCorrectaManejoDefensivo === TRUE) {
          setIsSwitchOpcionCorrectaManejoDefensivo(true);
        }
      }
      const listCorreos = await obtenerListaCorreos(true);
      setListaCorreos(listCorreos);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  };

  const handleInputChange = (event) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    setOpcionRespuesta({ ...opcionRespuesta, [name]: value });
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleSaveClick = async () => {
    // console.log('handleSaveClick');
    // lógica para guardar los cambios
    console.log('opcionRespuesta');
    console.log(opcionRespuesta);
    let objEnviar = {};
    objEnviar.descripcion = opcionRespuesta.descripcion;
    objEnviar.id = opcionRespuesta.id;
    objEnviar.color = opcionRespuesta.color;
    objEnviar.enviarCorreo = isSwitchOnEnviarCorreo;
    objEnviar.tomarFoto = isSwitchOnTomarFoto;
    objEnviar.notaObservacion = isSwitchOnObservacion;
    objEnviar.correos = correosSelected;
    objEnviar.pedirFecha = isSwitchOnFecha;
    objEnviar.critico = isSwitchOnCritico;
    objEnviar.manejoDefensivo = isSwitchManejoDefensivo;
    objEnviar.opcionCorrectaManejoDefensivo = isSwitchOpcionCorrectaManejoDefensivo;
    if (opcionRespuesta.id <= 0) {
      // console.log('crear correo');
      let creado = await crearOpcionesRespuesta(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (creado === true) {
        alert('Se creo correctamente');
        handleCancelClick();
      }
      if (creado === false) {
        alert('No se pudo crear');
      }
    }
    if (opcionRespuesta.id > 0) {
      // console.log('actualizar correo');
      let modificado = await modificarOpcionRespuesta(objEnviar);
      // console.log('modificado');
      // console.log(modificado);
      if (modificado === true) {
        alert('Se modifico correctamente');
        handleCancelClick();
      }
      if (modificado === false) {
        alert('No se pudo modificar');
      }
    }
  };

  const toggleSwitchEnviarCorreo = () => {
    if (!isSwitchOnEnviarCorreo === false) {
      setCorreosSelected(null);
    }
    setIsSwitchOnEnviarCorreo(!isSwitchOnEnviarCorreo);
  };

  const toggleSwitchTomarFoto = () => {
    setIsSwitchOnTomarFoto(!isSwitchOnTomarFoto);
  };

  const toggleSwitchPedirFecha = () => {
    setIsSwitchOnFecha(!isSwitchOnFecha);
  };

  const toggleSwitchCritico = () => {
    setIsSwitchOnCritico(!isSwitchOnCritico);
  };

  const toggleSwitchManejoDefensivo = () => {
    setIsSwitchManejoDefensivo((prev) => {
      const newValue = !prev;
      if (newValue === false) {
        setIsSwitchOpcionCorrectaManejoDefensivo(false);
      }
      return newValue;
    });
  };

  const toggleSwitchOpcionCorrectaManejoDefensivoManejoDefensivo = () => {
    setIsSwitchOpcionCorrectaManejoDefensivo(!isSwitchOpcionCorrectaManejoDefensivo);
  };

  const toggleSwitchObservacion = () => {
    setIsSwitchOnObservacion(!isSwitchOnObservacion);
  };

  const render = () => {
    // console.log('render correo');
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (cargandoPage === false && opcionRespuesta === null) {
        return (
          <div>
            <label> Sin data</label>
          </div>
        );
      }
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {titulo}
            </h2>
            <div className="mt-6 bg-white py-6 px-4 shadow-md rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-md font-medium text-gray-700"
                  >
                    OPCION RESPUESTA
                  </label>
                  <div className="mt-1">
                    <input
                      id="descripcion"
                      name="descripcion"
                      type="text"
                      value={opcionRespuesta.descripcion}
                      onChange={handleInputChange}
                      autoComplete="given-name"
                      className={ESTILO_INPUT_GENERICO}
                      required
                      maxLength={127}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ELEGIR COLOR
                  </label>
                  <div className="mt-1">
                    <input
                      id="color"
                      name="color"
                      type="color"
                      value={opcionRespuesta.color}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ENVIAR CORREO SI ESTA OPCION ES SELECCIONADA
                  </label>
                  <div className="flex justify-start pt-2 ">
                    <SwitchComponent isOn={isSwitchOnEnviarCorreo} handleToggle={toggleSwitchEnviarCorreo} />
                    <p className="ml-4" >{isSwitchOnEnviarCorreo ? "SI" : "NO"}</p>
                  </div>
                </div>

                {isSwitchOnEnviarCorreo === true &&
                  <div>
                    <Select
                      options={listaCorreos}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={correosSelected}
                      onChange={(e) => {
                        setCorreosSelected(e);
                      }}
                      placeholder={'SELECCIONE CORREOS'}
                    />
                  </div>
                }

                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    TOMAR FOTO SI ESTA OPCION ES SELECCIONADA
                  </label>
                  <div className="flex justify-start pt-2 ">
                    <SwitchComponent isOn={isSwitchOnTomarFoto} handleToggle={toggleSwitchTomarFoto} />
                    <p className="ml-4" >{isSwitchOnTomarFoto ? "SI" : "NO"}</p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="nombre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    PEDIR NOTA DE DESCRIPCION/OBSERVACION
                  </label>
                  <div className="flex justify-start pt-2 ">
                    <SwitchComponent isOn={isSwitchOnObservacion} handleToggle={toggleSwitchObservacion} />
                    <p className="ml-4" >{isSwitchOnObservacion ? "SI" : "NO"}</p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="fecha"
                    className="block text-sm font-medium text-gray-700"
                  >
                    PEDIR FECHA SI ESTA OPCION ES SELECCIONADA
                  </label>
                  <div className="flex justify-start pt-2 ">
                    <SwitchComponent isOn={isSwitchOnFecha} handleToggle={toggleSwitchPedirFecha} />
                    <p className="ml-4" >{isSwitchOnFecha ? "SI" : "NO"}</p>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="fecha"
                    className="block text-sm font-medium text-gray-700"
                  >
                    CRITICO
                  </label>
                  <div className="flex justify-start pt-2 ">
                    <SwitchComponent isOn={isSwitchOnCritico} handleToggle={toggleSwitchCritico} />
                    <p className="ml-4" >{isSwitchOnCritico ? "SI" : "NO"}</p>
                  </div>
                </div>

                <div className="card">
                  <div>
                    <label
                      htmlFor="fecha"
                      className="block text-sm font-medium text-gray-700"
                    >
                      MANEJO DEFENSIVO
                    </label>
                    <div className="flex justify-start pt-2 ">
                      <SwitchComponent isOn={isSwitchManejoDefensivo} handleToggle={toggleSwitchManejoDefensivo} />
                      <p className="ml-4" >{isSwitchManejoDefensivo ? "SI" : "NO"}</p>
                    </div>
                  </div>

                  {isSwitchManejoDefensivo === true &&
                    <div className="pl-4">
                      <label
                        htmlFor="fecha"
                        className="block text-sm font-medium text-gray-700"
                      >
                        OPCION CORRECTA MANEJO DEFENSIVO
                      </label>
                      <div className="flex justify-start pt-2 ">
                        <SwitchComponent isOn={isSwitchOpcionCorrectaManejoDefensivo} handleToggle={toggleSwitchOpcionCorrectaManejoDefensivoManejoDefensivo} />
                        <p className="ml-4" >{isSwitchOpcionCorrectaManejoDefensivo ? "SI" : "NO"}</p>
                      </div>
                    </div>
                  }
                </div>

                <div className="flex justify-end">
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_CANCELAR}
                      onClick={handleCancelClick}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className={ESTILO_BOTON_GENERAL_ACEPTAR}
                      onClick={handleSaveClick}
                    >
                      Aceptar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (err) {
      console.log('ModificarCorreos render err');
      console.log(err);
      return (
        <div>
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}
