// import moment from "moment/moment";
import moment from "moment";
import { POST, PUT, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function obtenerListaPieDePagina() {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/obtener-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                // body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
            if (Array.isArray(rows) === true) {
                for (let i = 0; i < rows.length; i++) {
                    rows[i].fechaProceso = moment(rows[i].fechaProceso).format('DD/MM/YYYY HH:mm');
                }
            }
        }
        return rows;
    } catch (err) {
        console.log("obtenerFormularioChecklist err");
        console.log(err);
        return null;
    }
}

export async function guardarPieDePagina(obj) {
    try {
        let guardo = { error: false, };
        const { code, id } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/guardar-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('id');
        // console.log(id);
        if (code === SUCCESS) {
            guardo.id = id;
        }
        return guardo;
    } catch (err) {
        console.log("obtenerFormularioChecklist err");
        console.log(err);
        return { error: false, };;
    }
}

export async function deshabilitarInputPieDePagina(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/deshabilitar-formulario-checklist/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("deshabilitarInputFormularioChecklist err");
        console.log(err);
        return false;
    }
}

export async function habilitarInputPieDePagina(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/habilitar-formulario-checklist/",
            {
                headers: getHeaders(),
                method: PUT,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("habilitarInputFormularioChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerPieDePaginaByTipoChecklist(obj) {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/obtener-pie-de-pagina-by-id-tipo-checklist",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerFormularioChecklist err");
        console.log(err);
        return null;
    }
}

//**DETALLE PIE DE PAGINA */
export async function obtenerDetalleListaPieDePagina(obj) {
    try {
        let rows = [];
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/obtener-detalle-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerDetalleListaPieDePagina err");
        console.log(err);
        return [];
    }
}

export async function guardarDetallePieDePagina(obj) {
    try {
        let guardo = { error: true, };
        const { code, id } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/guardar-detalle-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('id');
        // console.log(id);
        if (code === SUCCESS) {
            guardo.error = false;
            guardo.id = id;
        }
        return guardo;
    } catch (err) {
        console.log("guardarDetallePieDePagina err");
        console.log(err);
        return { error: true, };
    }
}

export async function actualizarFormularioDetallePieDePagina(obj) {
    try {
        let guardo = { error: true, };
        const { code, } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/actualizar-formulario-detalle-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('id');
        // console.log(id);
        if (code === SUCCESS) {
            guardo.error = false;
        }
        return guardo;
    } catch (err) {
        console.log("guardarDetallePieDePagina err");
        console.log(err);
        return { error: true, };
    }
}

export async function intercambiarPosicionPieDePaginaChecklist(obj) {
    try {
        let response = false;
        const { code } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/intercambiar-posicion-formulario-checklist/",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        if (code === SUCCESS) {
            response = true;
        }
        return response;
    } catch (err) {
        console.log("intercambiarPosicionFormularioChecklist err");
        console.log(err);
        return false;
    }
}

export async function obtenerPieDePaginaByIdPieDePagina(obj) {
    try {
        let rows = null;
        const { code, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/pie-de-pagina/obtener-detalle-pie-de-pagina-by-id-pie-de-pagina",
            {
                headers: getHeaders(),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            rows = body;
        }
        return rows;
    } catch (err) {
        console.log("obtenerPieDePaginaByIdPieDePagina err");
        console.log(err);
        return null;
    }
}