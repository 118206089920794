import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import shortid from "shortid";
import { obtenerInfoChecklist } from "../api/checklist";
import { obtenerPieDePaginaByIdPieDePagina, obtenerPieDePaginaByTipoChecklist } from "../api/api-pie-de-pagina";


const ComponentDetalleChecklist = ({ detalleChecklist, infoFormulario, showFooter = true }) => {

    const location = useLocation();
    const [pieDePagina, setPieDePagina] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        // console.log('detalleChecklist', detalleChecklist);
        // console.log('infoFormulario', infoFormulario);
        const data = location.state;
        // console.log("data");
        // console.log(data);
        const idChecklist = data !== undefined && data.idChecklist !== undefined && data.idChecklist !== null ? data.idChecklist : null;
        const info = await obtenerInfoChecklist({ id: idChecklist });
        // console.log('info');
        // console.log(info);
        if (info !== null) {
            const tipoChecklist = await obtenerPieDePaginaByTipoChecklist({ idTipoChecklist: info.idTipoChecklist });
            // console.log('tipoChecklist', tipoChecklist);
            if (Array.isArray(tipoChecklist) === true && tipoChecklist.length > 0) {
                const piePagina = await obtenerPieDePaginaByIdPieDePagina({ idPieDePagina: tipoChecklist[0].id });
                // console.log('pieDePagina', piePagina);
                setPieDePagina(piePagina);
            }
        }
    };

    const render = () => {
        try {
            return (
                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <div
                        key={shortid.generate()}
                        style={{
                            marginBottom: '20px',
                            padding: '10px',
                            backgroundColor: '#6c757d', // Bootstrap "secondary" color
                            color: 'white',
                            borderRadius: '5px',
                        }}
                    >
                        <label style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                            FORMULARIO
                        </label>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                            }}
                        >
                            {Array.isArray(infoFormulario) && infoFormulario.length > 0 && infoFormulario.map((info) => (
                                <div
                                    key={shortid.generate()}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column', // Ensure label and value stack vertically
                                        borderBottom: '1px solid #ccc',
                                        padding: '10px 0',
                                        backgroundColor: '#f8f9fa',
                                        width: '50%', // Occupy 50% width for two items per row
                                    }}
                                >
                                    <div>
                                        <p style={{ color: '#212529', fontWeight: 'bold' }}>{info.label}</p>
                                        <p style={{ color: '#212529' }}>{info.value}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {Array.isArray(detalleChecklist) === true && detalleChecklist.map((seccion) => (
                        <div
                            key={shortid.generate()}
                            style={{
                                marginBottom: '20px',
                                padding: '10px',
                                backgroundColor: '#6c757d', // Bootstrap "secondary" color
                                color: 'white',
                                borderRadius: '5px',
                            }}
                        >
                            <label style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                                {seccion.nombreSeccion}
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1px solid #ccc',
                                    padding: '10px 0',
                                }}
                            >
                                <div style={{ flex: 8 }}>
                                    <label style={{ fontWeight: 'bold' }}>PREGUNTA</label>
                                </div>
                                <div style={{ flex: 4 }}>
                                    <label style={{ fontWeight: 'bold' }}>RESPUESTA</label>
                                </div>
                            </div>
                            {seccion.preguntas.map((pregunta) => (
                                <>
                                    <div
                                        key={shortid.generate()}
                                        style={{
                                            display: 'flex',
                                            borderBottom: '1px solid #ccc',
                                            padding: '10px 0',
                                            backgroundColor: '#f8f9fa',
                                        }}
                                    >
                                        <div style={{ flex: 8 }}>
                                            <label style={{ color: '#212529' }}>{pregunta.labelPregunta}</label>
                                        </div>
                                        <div style={{ flex: 4 }}>
                                            <p style={{ color: '#212529' }}>{pregunta.labelOpcionRespuesta}</p>
                                            <p style={{ color: '#212529' }}>{pregunta.respuesta}</p>
                                            {pregunta.urlArchivo &&
                                                <p style={{ color: '#212529' }}>
                                                    <a
                                                        href={pregunta.urlArchivo}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: '#212529', textDecoration: 'none' }}
                                                    >
                                                        VER IMAGEN
                                                    </a>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    {pregunta.urlArchivo &&
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '10px 0',
                                                backgroundColor: '#f8f9fa',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <a
                                                href={pregunta.urlArchivo}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: '#212529', textDecoration: 'none' }}
                                            >
                                                {pregunta.urlArchivo}
                                            </a>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    ))}
                    {showFooter === true && pieDePagina !== null &&
                        <>
                            {pieDePagina.map((item) => (
                                <div
                                    key={shortid.generate()}
                                    style={{
                                        marginBottom: '12px',
                                        padding: '10px',
                                        backgroundColor: '#6c757d', // Bootstrap "secondary" color
                                        color: 'white',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <label style={{ fontSize: '1.25rem', fontWeight: 'bold', color: 'black' }}>
                                        {item.text}
                                    </label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            flexDirection: 'row',
                                        }}
                                    >
                                    </div>
                                </div>
                            ))}
                        </>

                    }
                </div>
            );
        } catch (err) {
            console.error('Error en render', err);
            return (<div>{JSON.stringify(err)}</div>)
        }
    };

    return render();
}

export default ComponentDetalleChecklist;