import { useState, useEffect } from "react";
import cargandoCard from "../../components/cargando-card";
import noContentCard from "../../components/no-content-card";
import { actualizarPreguntasChecklist, modificarChecklist, obtenerInfoChecklist, obtenerPreguntasChecklist } from "../../api/checklist";
import { useHistory, useLocation } from "react-router-dom";
import {
    ESTILO_BOTON_DOWN,
    ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_MODAL_ACEPTAR,
    ESTILO_BOTON_UP,
    ESTILO_INPUT_GENERICO, ESTILO_ROTATE_180, ESTILO_ROTATE_45,
    ESTILO_SECCION_BOTON_DOWN,
    ESTILO_SECCION_BOTON_UP
} from "../../utils/const/estilos-const";
import { obtenerListaTipoCarroceria } from "../../api/tipo-carroceria";
import { obtenerListaTipoCombustible } from "../../api/tipo-combustible";
import { obtenerListaMarcaVehiculo } from "../../api/marca-vehiculo";
import { obtenerListaCiudad } from "../../api/ciudad";
import { obtenerListaArea } from "../../api/area";
import { obtenerListaTipoChecklist } from "../../api/tipo-checklist";
import Select from "react-select";
import { ArrowDownCircle, ArrowUpCircle, CheckSquare, ChevronLeft, ChevronsLeft, Plus, RefreshCcw, Save, Trash, XSquare, } from "feather-icons-react/build/IconComponents";
import {
    INT_PREGUNTA_COMENTARIO, INT_PREGUNTA_FIRMA, INT_PREGUNTA_IMAGEN,
    INT_PREGUNTA_SELECCION_MULTIPLE, INT_PREGUNTA_SELECCION_SIMPLE,
    STRING_PREGUNTA_COMENTARIO, STRING_PREGUNTA_FIRMA, STRING_PREGUNTA_IMAGEN,
    STRING_PREGUNTA_SELECCION_MULTIPLE, STRING_PREGUNTA_SELECCION_SIMPLE
} from "../../utils/const/tipo-pregunta-const";
import { obtenerListaTipoOpcionesRespuesta } from "../../api/configuracion";
import DropdownPregunta from "../../components/dropdown-pregunta-component";
import FooterPregunta from "../../components/footer-pregunta-component";
import { crearSeccion } from "../../api/seccion";
import { crearPreguntaSeccion } from "../../api/preguntas-seccion";
import { obtenerListaIconos } from "../../api/gestionar-imagenes";
import { deshabilitarOpcionRespuestaPregunta, guardarOpcionRespuestaPregunta, restaurarOpcionRespuestaPregunta } from "../../api/opciones-respuesta-pregunta";
import { INT_QUERY_DELETE, INT_QUERY_UPDATE } from "../../utils/const/int-const";
import { obtenerListaTipoVehiculo } from "../../api/tipo-vehiculo";
import SwitchComponent from "../../components/switch";
import { TRUE } from "../../utils/const/bool-const";

export default function PreguntasChecklist() {

    const location = useLocation();
    const history = useHistory();
    const [cargandoPage, setCargandoPage] = useState(true);
    const [checklistSelected, setChecklistSelected] = useState({});
    const [noContent, setNoContent] = useState(false);
    const [listaSecciones, setListaSecciones] = useState([]);
    const [isHoveredBackButton, setIsHoveredBackButton] = useState(false);

    const [listaTipoChecklist, setListaTipoChecklist] = useState([]);
    const [tipoChecklistSelected, setTipoChecklistSelected] = useState(null);

    // const [listaArea, setListaArea] = useState([]);
    // const [areaSelected, setAreaSelected] = useState(null);

    // const [listaCiudad, setListaCiudad] = useState([]);
    // const [ciudadSelected, setCiudadSelected] = useState(null);

    // const [listaTipoVehiculo, setListaTipoVehiculo] = useState([]);
    // const [tipoVehiculoSelected, setTipoVehiculoSelected] = useState(null);

    // const [listaMarcaVehiculo, setListaMarcaVehiculo] = useState([]);
    // const [marcaVehiculoSelected, setMarcaVehiculoSelected] = useState(null);

    // const [listaTipoCombustible, setListaTipoCombustible] = useState([]);
    // const [tipoCombustibleSelected, setTipoCombustibleSelected] = useState(null);

    // const [listaTipoCarroceria, setListaTipoCarroceria] = useState([]);
    // const [tipoCarroceriaSelected, setTipoCarroceriaSelected] = useState(null);

    const [listaOpcionesRespuesta, setListaOpcionesRespuesta] = useState([]);
    const [listaIconos, setListaIconos] = useState(null);

    const [manejoDefensivo, setManejoDefensivo] = useState(false);

    useEffect(() => {
        iniciar();
    }, []);

    const iniciar = async () => {
        try {
            const data = location.state;
            console.log("data");
            console.log(data);
            checklistSelected.id = data.id;
            const infoChecklist = await obtenerInfoChecklist(checklistSelected);
            // console.log('infoChecklist');
            // console.log(infoChecklist);
            setChecklistSelected(infoChecklist);
            if (infoChecklist === null) {
                setNoContent(true);
                return;
            }

            if (data.manejoDefensivo === TRUE) {
                setManejoDefensivo(true);
            }

            const listaPivoteSeccion = await obtenerPreguntasChecklist(checklistSelected);
            setListaSecciones(listaPivoteSeccion);

            const listaTC = await obtenerListaTipoChecklist(true);
            setListaTipoChecklist(listaTC);
            if (infoChecklist.idTipoChecklist !== null) {
                const tipoCheckLis = listaTC.find(e => e.value === infoChecklist.idTipoChecklist);
                setTipoChecklistSelected(tipoCheckLis !== undefined ? tipoCheckLis : null);
            }

            // const listaA = await obtenerListaArea(true);
            // setListaArea(listaA);
            // if (infoChecklist.idArea !== null) {
            //     const tipoAr = listaA.find(e => e.value === infoChecklist.idArea);
            //     setAreaSelected(tipoAr !== undefined ? tipoAr : null);
            // }

            // const listaCiu = await obtenerListaCiudad(true);
            // setListaCiudad(listaCiu);
            // if (infoChecklist.idCiudad !== null) {
            //     const ciuSelected = listaCiu.find(e => e.value === infoChecklist.idCiudad);
            //     setCiudadSelected(ciuSelected !== undefined ? ciuSelected : null);
            // }

            // const listaMV = await obtenerListaMarcaVehiculo(true);
            // setListaMarcaVehiculo(listaMV);
            // if (infoChecklist.idMarcaVehiculo !== null) {
            //     const marcaV = listaMV.find(e => e.value === infoChecklist.idMarcaVehiculo);
            //     setMarcaVehiculoSelected(marcaV !== undefined ? marcaV : null);
            // }

            // const listaTV = await obtenerListaTipoVehiculo(true);
            // setListaTipoVehiculo(listaTV);
            // if (infoChecklist.idTipoVehiculo !== null) {
            //     const tipoV = listaTV.find(e => e.value === infoChecklist.idTipoVehiculo);
            //     setTipoVehiculoSelected(tipoV !== undefined ? tipoV : null);
            // }

            // const listaTComb = await obtenerListaTipoCombustible(true);
            // setListaTipoCombustible(listaTComb);
            // if (infoChecklist.idTipoCombustible !== null) {
            //     const tipoCombus = listaTComb.find(e => e.value === infoChecklist.idTipoCombustible);
            //     setTipoCombustibleSelected(tipoCombus !== undefined ? tipoCombus : null);
            // }

            // const listaTCarroC = await obtenerListaTipoCarroceria(true);
            // setListaTipoCarroceria(listaTCarroC);
            // if (infoChecklist.idTipoCarroceria !== null) {
            //     const tipoCarroc = listaTCarroC.find(e => e.value === infoChecklist.idTipoCarroceria);
            //     setTipoCarroceriaSelected(tipoCarroc !== undefined ? tipoCarroc : null);
            // }

            const optRespuesta = await obtenerListaTipoOpcionesRespuesta(true);
            // console.log('optRespuesta');
            // console.log(optRespuesta);
            setListaOpcionesRespuesta(optRespuesta);

            const listaIco = await obtenerListaIconos();
            // console.log('listaIco');
            // console.log(listaIco);
            let listaIconosChecklist = [];
            for (let i = 0; i < listaIco.length; i++) {
                const element = listaIco[i];
                let obj = {};
                obj.value = element.id;
                obj.label = element.descripcion;
                obj.url = element.url;
                listaIconosChecklist.push(obj);
            }
            setListaIconos(listaIconosChecklist);

            setCargandoPage(false);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
            setCargandoPage(false);
            setNoContent(true);
        }
    };

    const handleAtrasClick = () => {
        history.goBack();
    };

    const handleInputChange = (event) => {
        // console.log('handleInputChange');
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        setChecklistSelected({ ...checklistSelected, [name]: value });
    };

    const validarActualizar = () => {
        try {
            // console.log('validar');
            // console.log(elemento);
            let error = "";
            if (checklistSelected.nombre === undefined || checklistSelected.nombre === null || String(checklistSelected.nombre).trim().length === 0) {
                error = error + "Nombre es requerido \n";
            }
            if (checklistSelected.nota === undefined || checklistSelected.nota === null) {
                checklistSelected.nota = "";
            }
            if (String(error).length > 0) {
                alert(error);
                return false;
            }
            return true;
        } catch (err) {
            return false;
        }
    };

    const onPressActualizar = async () => {
        try {
            const validarAct = validarActualizar();
            if (validarAct === false) {
                return;
            }
            checklistSelected.idTipoChecklist = tipoChecklistSelected !== null ? tipoChecklistSelected.value : null;
            // checklistSelected.idArea = areaSelected !== null ? areaSelected.value : null;
            // checklistSelected.idCiudad = ciudadSelected !== null ? ciudadSelected.value : null;
            // checklistSelected.idTipoVehiculo = tipoVehiculoSelected !== null ? tipoVehiculoSelected.value : null;
            // checklistSelected.idMarcaVehiculo = marcaVehiculoSelected !== null ? marcaVehiculoSelected.value : null;
            // checklistSelected.idTipoCarroceria = tipoCarroceriaSelected !== null ? tipoCarroceriaSelected.value : null;
            // checklistSelected.idTipoCombustible = tipoCombustibleSelected !== null ? tipoCombustibleSelected.value : null;
            const actualizo = await modificarChecklist(checklistSelected);
            if (actualizo === false) {
                alert('No se pudo actualizar, intente nuevamente');
                return;
            }
            if (actualizo === true) {
                alert('Se actualizo correctamente');
                handleAtrasClick();
            }
        } catch (err) {

        }
    };

    const handleAddSection = async () => {
        // console.log('checklistSelected');
        // console.log(checklistSelected);
        const seccion = await crearSeccion({ idChecklist: checklistSelected.id, });
        // console.log('seccion');
        // console.log(seccion);
        if (seccion.creado === true) {
            setListaSecciones((prevlistaSecciones) => [...prevlistaSecciones, { id: seccion.id, query: INT_QUERY_UPDATE, }]);
        }
        if (seccion.creado === false) {
            alert("No se pudo crear nueva seccion, intente nuevamente");
        }
    };

    const handleAddPregunta = async (tipoPregunta, index) => {
        // console.log('handleAddPregunta');
        // console.log('checklistSelected');
        // console.log(checklistSelected);
        // console.log(tipoPregunta);
        // console.log(listaSecciones);
        // console.log(listaSecciones[index]);
        let objEnviar = {};
        objEnviar.idChecklist = checklistSelected.id;
        objEnviar.idSeccion = listaSecciones[index].id;
        objEnviar.urlIcon = null;
        objEnviar.tipoPregunta = tipoPregunta;
        objEnviar.codigoPregunta = "";
        objEnviar.tituloPregunta = "";
        const crearPregunta = await crearPreguntaSeccion(objEnviar);
        // console.log('crearPregunta');
        // console.log(crearPregunta);
        if (crearPregunta.creado === false) {
            alert('No se pudo crear pregunta, intente nuevamente');
            return;
        }
        const newListaSecciones = [...listaSecciones];
        if (newListaSecciones[index].preguntas === undefined) {
            newListaSecciones[index].preguntas = [];
        }
        switch (tipoPregunta) {
            case INT_PREGUNTA_SELECCION_SIMPLE:
                newListaSecciones[index].preguntas.push({
                    id: crearPregunta.id,
                    tituloPregunta: "",
                    opcionesRespuestasHabilitadas: [],
                    opcionesRespuestasDeshabilitadas: [],
                    multiple: false,
                    tipo: STRING_PREGUNTA_SELECCION_SIMPLE,
                    idTipo: INT_PREGUNTA_SELECCION_SIMPLE,
                    urlIcon: null,
                    query: INT_QUERY_UPDATE,
                    manejoDefensivo: false,
                });
                break;
            case INT_PREGUNTA_SELECCION_MULTIPLE:
                newListaSecciones[index].preguntas.push({
                    id: crearPregunta.id,
                    tituloPregunta: "",
                    opcionesRespuestasHabilitadas: [],
                    opcionesRespuestasDeshabilitadas: [],
                    multiple: true,
                    tipo: STRING_PREGUNTA_SELECCION_MULTIPLE,
                    idTipo: INT_PREGUNTA_SELECCION_MULTIPLE,
                    urlIcon: null,
                    query: INT_QUERY_UPDATE,
                });
                break;
            case INT_PREGUNTA_FIRMA:
                newListaSecciones[index].preguntas.push({
                    id: crearPregunta.id,
                    tituloPregunta: "",
                    multiple: false,
                    tipo: STRING_PREGUNTA_FIRMA,
                    idTipo: INT_PREGUNTA_FIRMA,
                    urlIcon: null,
                    query: INT_QUERY_UPDATE,
                });
                break;
            case INT_PREGUNTA_IMAGEN:
                newListaSecciones[index].preguntas.push({
                    id: crearPregunta.id,
                    tituloPregunta: "",
                    multiple: false,
                    tipo: STRING_PREGUNTA_IMAGEN,
                    idTipo: INT_PREGUNTA_IMAGEN,
                    urlIcon: null,
                    query: INT_QUERY_UPDATE,
                });
                break;
            case INT_PREGUNTA_COMENTARIO:
                newListaSecciones[index].preguntas.push({
                    id: crearPregunta.id,
                    tituloPregunta: "",
                    multiple: false,
                    tipo: STRING_PREGUNTA_COMENTARIO,
                    idTipo: INT_PREGUNTA_COMENTARIO,
                    urlIcon: null,
                    query: INT_QUERY_UPDATE,
                });
                break;
        }
        setListaSecciones(newListaSecciones);
    };

    const handleInputChangeSection = (event, index) => {
        // console.log('handleInputChangeSection');
        // console.log(listaSecciones);
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        const newListaSecciones = [...listaSecciones];
        newListaSecciones[index] = {
            ...newListaSecciones[index],
            [name]: value,
        };
        setListaSecciones(newListaSecciones);
    };

    const handleInputChangePregunta = (event, indexSeccion, indexPregunta) => {
        // console.log('handleInputChangeSection');
        // console.log('indexSeccion->' + indexSeccion);
        // console.log('indexPregunta->' + indexPregunta);
        const { name, value } = event.target;
        // console.log(name);
        // console.log(value);
        const newListaSecciones = [...listaSecciones];
        newListaSecciones[indexSeccion].preguntas[indexPregunta] = {
            ...newListaSecciones[indexSeccion].preguntas[indexPregunta],
            [name]: value,
        };
        // console.log('newListaSecciones');
        // console.log(newListaSecciones);
        setListaSecciones(newListaSecciones);
    };

    const renderPreguntasSeccion = (indexSeccion) => {
        try {
            // console.log('renderPreguntasSeccion');
            // console.log(listaSecciones);
            // console.log(listaSecciones[indexSeccion]);
            const arrowStyle = {
                width: "10%",
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            };
            const styleRow = {
                marginTop: 5,
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                display: 'flex',
                flexDirection: 'row'
            };
            if (listaSecciones[indexSeccion].preguntas !== undefined && listaSecciones[indexSeccion].preguntas.length > 0) {
                return listaSecciones[indexSeccion].preguntas.map((pregunta, indexPregunta) => {
                    switch (pregunta.idTipo) {
                        case INT_PREGUNTA_SELECCION_SIMPLE:
                            return renderPreguntaSimple(indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow);
                        // case INT_PREGUNTA_SELECCION_MULTIPLE:
                        //     return renderPreguntaMultiple(indexSeccion, pregunta, indexPregunta); break;
                        case INT_PREGUNTA_FIRMA:
                            return renderPreguntaFirma(indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow);
                        case INT_PREGUNTA_IMAGEN:
                            return renderPreguntaImagen(indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow);
                        case INT_PREGUNTA_COMENTARIO:
                            return renderPreguntaComentario(indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow);
                    }
                });
            }
        } catch (err) {
            console.log('renderPreguntasSeccion err');
            console.log(err);
        }
    };

    const renderSections = () => {
        try {
            // console.log('renderSections');
            return listaSecciones.map((section, indexSeccion) => {
                // console.log('section');
                // console.log(section);
                if (section.query === INT_QUERY_UPDATE) {
                    return (
                        <div
                            className="rounded shadow-2xl bg-white mb-4 px-4 py-4"
                            key={indexSeccion}
                        >
                            <div className="flex direction-row" >
                                <input
                                    id="nombreSeccion"
                                    name="nombreSeccion"
                                    type="text"
                                    value={section.nombreSeccion}
                                    onChange={e => { handleInputChangeSection(e, indexSeccion); }}
                                    className={ESTILO_INPUT_GENERICO}
                                    required
                                    maxLength={255}
                                    placeholder="NOMBRE SECCION"
                                />
                                <DropdownPregunta
                                    handleAddPregunta={handleAddPregunta}
                                    indexSeccion={indexSeccion}
                                />
                                {renderUpDownButtonSecciones(indexSeccion)}
                            </div>
                            <br />
                            <div className="pl-4">
                                <label className="font-bold" >PREGUNTAS</label>
                                {renderPreguntasSeccion(indexSeccion)}
                            </div>
                            <hr />
                            <div className="flex justify-between mt-2">
                                <label>SECCION NRO: {indexSeccion}</label>
                                <button
                                    type="button"
                                    className={`${ESTILO_BOTON_GENERAL_ACEPTAR} flex items-center`}
                                    onClick={() => handleEliminarSeccion(indexSeccion)}
                                    title="ELIMINAR SECCION"
                                >
                                    <Trash className={`mr-2 ${ESTILO_ROTATE_45}`} />
                                    ELIMINAR SECCION
                                </button>
                            </div>
                        </div>
                    );
                }
                return (<div></div>);
            });
        } catch (err) {
            console.log("renderSections err");
            console.log(err);
            return <div>{JSON.stringify(err)}</div>;
        }
    };

    const toggleSwitchManejoDefensivo = (indexSeccion, indexPregunta) => {
        let newListaSecciones = [...listaSecciones];
        // console.log('toggleSwitchManejoDefensivo', newListaSecciones);
        // console.log('indexSeccion', indexSeccion);
        // console.log('indexPregunta', indexPregunta);
        newListaSecciones[indexSeccion].preguntas[indexPregunta].manejoDefensivo = !newListaSecciones[indexSeccion].preguntas[indexPregunta].manejoDefensivo;
        setListaSecciones(newListaSecciones);
    };

    const renderPreguntaSimple = (indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow) => {
        try {
            // console.log('renderPreguntaSimple indexSeccion', indexSeccion);
            // console.log('renderPreguntaSimple pregunta', pregunta);
            // console.log('renderPreguntaSimple indexPregunta', indexPregunta)
            if (pregunta.query === INT_QUERY_UPDATE) {
                const isOn = pregunta.manejoDefensivo !== undefined && (pregunta.manejoDefensivo === true || pregunta.manejoDefensivo === 1) ? true : false;
                // const isOn = false;
                return (
                    <div
                        style={styleRow}
                    >
                        {renderUpDownButton(arrowStyle, indexSeccion, indexPregunta)}
                        <div
                            style={{
                                width: '80%'
                            }}
                        >
                            <div className="flex flex-row w-full" >
                                {renderRowIconoPregunta(indexSeccion, pregunta, indexPregunta)}
                            </div>
                            <FooterPregunta
                                indexPregunta={indexPregunta}
                                indexSeccion={indexSeccion}
                                handleEliminarPregunta={handleEliminarPregunta}
                            />
                        </div>

                        {manejoDefensivo === true &&
                            <div
                                style={{
                                    width: '50px',
                                }}
                            >
                                <label className="flex pl-4 font-bold">MANEJO DEFENSIVO</label>
                                <div
                                    style={{
                                        paddingTop: '12px',
                                    }}
                                >
                                    <div
                                        className={`flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isOn ? 'bg-green-400' : 'bg-gray-300'}`}
                                        onClick={() => { toggleSwitchManejoDefensivo(indexSeccion, indexPregunta); }}
                                    >
                                        <div
                                            className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${isOn ? 'translate-x-6' : ''}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                );
            }
            return (<div></div>);
        } catch (err) {
            console.log('renderPreguntaSimple err');
            console.log(err);
        }
    };

    /*const renderPreguntaMultiple = (indexSeccion, pregunta, indexPregunta) => {
        try {
            return (
                <div>
                    <div className="flex flex-row w-full" >
                        {renderRowIconoPregunta(indexSeccion, pregunta, indexPregunta)}
                    </div>
                    <FooterPregunta
                        indexPregunta={indexPregunta}
                        indexSeccion={indexSeccion}
                        handleEliminarPregunta={handleEliminarPregunta}
                    />
                </div>
            );
        } catch (err) {
            console.log('renderPreguntaMultiple err');
            console.log(err);
        }
    }*/

    const renderRowIconoPregunta = (indexSeccion, pregunta, indexPregunta) => {
        return (
            <>
                <div style={{
                    width: "29%",
                    flexDirection: "column",
                    display: "flex",
                }}
                >
                    <Select
                        options={listaIconos}
                        isSearchable={true}
                        isClearable={true}
                        value={listaIconos.find(e => e.value === pregunta.valueUrlIcon)}
                        onChange={(event) => {
                            handlePreguntaIcon(indexSeccion, indexPregunta, event);
                        }}
                        placeholder="Seleccione Icono"
                    />
                    <div
                        style={{
                            height: "8px",
                        }}
                    ></div>
                    {pregunta.urlIcon !== null &&
                        <div className="flex justify-end" >
                            <img src={pregunta.urlIcon} width={48} height={48} />
                        </div>
                    }
                </div>
                <div
                    style={{
                        width: "1%",
                    }}
                ></div>
                <div style={{
                    width: "70%",
                    flexDirection: "column",
                    display: "flex",
                }}
                >
                    <input
                        id="tituloPregunta"
                        name="tituloPregunta"
                        type="text"
                        value={pregunta.tituloPregunta}
                        onChange={e => handleInputChangePregunta(e, indexSeccion, indexPregunta)}
                        className={ESTILO_INPUT_GENERICO}
                        required
                        maxLength={255}
                        placeholder="TITULO PREGUNTA"
                    />
                    <div
                        style={{
                            height: "8px",
                        }}
                    ></div>
                    <div
                        style={{
                            width: "50%",
                        }}
                    >
                        <Select
                            options={listaOpcionesRespuesta}
                            isSearchable={true}
                            isClearable={true}
                            value={null}
                            onChange={(event) => {
                                handleOpcionesRespuesta(indexSeccion, indexPregunta, event);
                            }}
                            placeholder="Seleccione Opcion Respuesta"
                        /></div>
                    <div
                        style={{
                            height: "8px",
                        }}
                    ></div>
                    {pregunta.opcionesRespuestasHabilitadas !== null &&
                        <div>
                            <label className="font-bold">OPCIONES RESPUESTA HABILITADAS</label>
                            {renderOpcionesRespuestaHabilitadas(indexSeccion, indexPregunta, pregunta)}
                        </div>
                    }
                    <div
                        style={{
                            height: "8px",
                        }}
                    ></div>
                    {pregunta.opcionesRespuestasDeshabilitadas !== null &&
                        <div>
                            <label className="font-bold">OPCIONES RESPUESTA DESHABILITADAS</label>
                            {renderOpcionesRespuestaDeshabilitadas(indexSeccion, indexPregunta, pregunta)}
                        </div>
                    }
                    <div
                        style={{
                            height: "8px",
                        }}
                    ></div>
                </div>
            </>
        );
    };

    const renderOpcionesRespuestaHabilitadas = (indexSeccion, indexPregunta, pregunta) => {
        try {
            // console.log('renderOpcionesRespuestaHabilitadas');
            // console.log('listaSecciones');
            // console.log(listaSecciones);
            // console.log('indexSeccion');
            // console.log(indexSeccion);
            // console.log('indexPregunta');
            // console.log(indexPregunta);
            // console.log('pregunta');
            // console.log(pregunta);
            return (
                <div className="flex flex-row space-x-2">
                    {listaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas.map((element, indexOpcionRespuesta) => (
                        <span className="flex items-center bg-gray-200 p-2 rounded">
                            {element.label}
                            <XSquare title="Eliminar Opcion Respuesta" className="ml-2 cursor-pointer"
                                onClick={() => {
                                    eliminarOpcionRespuesta(indexSeccion, indexPregunta, indexOpcionRespuesta, element);
                                }}
                            />
                        </span>
                    ))}
                </div>
            );

        } catch (err) {
            console.log('renderOpcionesRespuestaHabilitadas err');
            console.log(err);
        }
    };

    const renderOpcionesRespuestaDeshabilitadas = (indexSeccion, indexPregunta, pregunta) => {
        try {
            return (
                <div className="flex flex-row space-x-2">
                    {listaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasDeshabilitadas.map((element, indexOpcionRespuesta) => (
                        <span className="flex items-center bg-gray-200 p-2 rounded">
                            {element.label}
                            <CheckSquare title="Restaurar Opcion Respuesta" className="ml-2 cursor-pointer"
                                onClick={() => {
                                    restaurarOpcionRespuesta(indexSeccion, indexPregunta, indexOpcionRespuesta, element);
                                }}
                            />
                        </span>
                    ))}
                </div>
            );
        } catch (err) {
            console.log('renderOpcionesRespuestaDeshabilitadas err');
            console.log(err);
        }
    };

    const renderPreguntaFirma = (indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow) => {
        try {
            return renderPreguntaSinOpcionRespuesta(indexSeccion, pregunta, indexPregunta, "TITULO FIRMA", arrowStyle, styleRow);
        } catch (err) {
            console.log('renderPreguntaFirma err');
            console.log(err);
        }
    };

    const renderPreguntaImagen = (indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow) => {
        try {
            return renderPreguntaSinOpcionRespuesta(indexSeccion, pregunta, indexPregunta, "TITULO IMAGEN", arrowStyle, styleRow);
        } catch (err) {
            console.log('renderPreguntaImagen err');
            console.log(err);
        }
    };

    const renderPreguntaComentario = (indexSeccion, pregunta, indexPregunta, arrowStyle, styleRow) => {
        try {
            return renderPreguntaSinOpcionRespuesta(indexSeccion, pregunta, indexPregunta, "TITULO COMENTARIO", arrowStyle, styleRow);
        } catch (err) {
            console.log('renderPreguntaComentario err');
            console.log(err);
        }
    };

    const renderPreguntaSinOpcionRespuesta = (indexSeccion, pregunta, indexPregunta, placeHolder, arrowStyle, styleRow) => {
        try {
            return (
                <div
                    style={styleRow}
                >
                    <hr />
                    {renderUpDownButton(arrowStyle, indexSeccion, indexPregunta)}
                    <div
                        style={{
                            width: '90%'
                        }}
                    >
                        <div className="flex flex-row w-full pt-2" >
                            <div style={{
                                width: "30%",
                            }}
                            >
                                <span className="font-bold">TIPO PREGUNTA: </span>
                                <span>{pregunta.tipo}</span>
                            </div>
                            <div className="flex flex-row w-full" >
                                <div style={{
                                    width: "70%",
                                }}
                                >
                                    <input
                                        id="tituloPregunta"
                                        name="tituloPregunta"
                                        type="text"
                                        value={pregunta.tituloPregunta}
                                        onChange={e => handleInputChangePregunta(e, indexSeccion, indexPregunta)}
                                        className={ESTILO_INPUT_GENERICO}
                                        required
                                        maxLength={255}
                                        placeholder={placeHolder}
                                    />
                                </div>
                            </div>
                        </div>

                        <FooterPregunta
                            indexPregunta={indexPregunta}
                            indexSeccion={indexSeccion}
                            handleEliminarPregunta={handleEliminarPregunta}
                        />
                    </div>
                </div>
            );
        } catch (err) {
            console.log('renderPreguntaSinOpcionRespuesta err');
            console.log(err);
            return (
                <div>
                    <label>ERROR</label>
                </div>
            );
        }
    };

    const renderUpDownButtonSecciones = (indexSeccion) => {
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row' }}
            >
                {indexSeccion > 0 &&
                    <button
                        type="button"
                        className={ESTILO_SECCION_BOTON_UP}
                        onClick={() => { moverSeccionUpClick(indexSeccion); }}
                        title="MOVER SECCION ARRIBA"
                    >
                        <ArrowUpCircle />
                    </button>
                }
                {indexSeccion < listaSecciones.length - 1 &&
                    <button
                        type="button"
                        className={ESTILO_SECCION_BOTON_DOWN}
                        onClick={() => { moverSeccionDownClick(indexSeccion); }}
                        title="MOVER SECCION ABAJO"
                    >
                        <ArrowDownCircle />
                    </button>}
            </div>
        );
    };

    const moverSeccionUpClick = (indexSeccion) => {
        if (indexSeccion === 0) return; // No mover si es el primer elemento        
        setListaSecciones((prevItems) => {
            const newItems = [...prevItems];
            [newItems[indexSeccion - 1], newItems[indexSeccion]] = [newItems[indexSeccion], newItems[indexSeccion - 1]];
            return newItems;
        });
    };

    const moverSeccionDownClick = (indexSeccion) => {
        if (indexSeccion === listaSecciones.length - 1) return; // No mover si es el último elemento
        setListaSecciones((prevItems) => {
            const newItems = [...prevItems];
            [newItems[indexSeccion + 1], newItems[indexSeccion]] = [newItems[indexSeccion], newItems[indexSeccion + 1]];
            return newItems;
        });
    };

    const renderUpDownButton = (arrowStyle, indexSeccion, indexPregunta) => {
        return (
            <div
                style={arrowStyle}
            >
                {indexPregunta > 0 &&
                    <button
                        type="button"
                        className={ESTILO_BOTON_UP}
                        onClick={() => { movePreguntaUpClick(indexPregunta, indexSeccion); }}
                        title="MOVER ARRIBA"
                    >
                        <ArrowUpCircle />
                    </button>
                }
                <div style={{ height: 10 }}></div>
                {indexPregunta < listaSecciones[indexSeccion].preguntas.length - 1 &&
                    <button
                        type="button"
                        className={ESTILO_BOTON_DOWN}
                        onClick={() => { movePreguntaDownClick(indexPregunta, indexSeccion); }}
                        title="MOVER ABAJO"
                    >
                        <ArrowDownCircle />
                    </button>}
            </div>
        );
    };

    const movePreguntaUpClick = (indexPregunta, indexSeccion) => {
        if (indexPregunta === 0) return; // No mover si es el primer elemento        
        setListaSecciones((prevItems) => {
            const newItems = [...prevItems];
            const updatedSeccion = { ...newItems[indexSeccion] };
            const updatedPreguntas = [...updatedSeccion.preguntas];
            [updatedPreguntas[indexPregunta - 1], updatedPreguntas[indexPregunta]] = [updatedPreguntas[indexPregunta], updatedPreguntas[indexPregunta - 1]];
            updatedSeccion.preguntas = updatedPreguntas;
            newItems[indexSeccion] = updatedSeccion;
            return newItems;
        });

    };

    const movePreguntaDownClick = (indexPregunta, indexSeccion) => {
        if (indexPregunta === listaSecciones[indexSeccion].preguntas.length - 1) return; // No mover si es el último elemento
        setListaSecciones((prevItems) => {
            const newItems = [...prevItems];
            const updatedSeccion = { ...newItems[indexSeccion] };
            const updatedPreguntas = [...updatedSeccion.preguntas];
            [updatedPreguntas[indexPregunta + 1], updatedPreguntas[indexPregunta]] = [updatedPreguntas[indexPregunta], updatedPreguntas[indexPregunta + 1]];
            updatedSeccion.preguntas = updatedPreguntas;
            newItems[indexSeccion] = updatedSeccion;
            return newItems;
        });
    };

    const handleEliminarSeccion = (indexSeccion) => {
        console.log('indexSeccion');
        console.log(indexSeccion);
        const userConfirmed = window.confirm('Confirma deshabilitar Seccion Nro: ' + (parseInt(indexSeccion)));
        if (userConfirmed) {
            let newListaSecciones = [...listaSecciones];
            newListaSecciones[indexSeccion].query = INT_QUERY_DELETE;
            setListaSecciones(newListaSecciones);
        }
    };

    const handleEliminarPregunta = (indexSeccion, indexPregunta) => {
        // console.log('listaSecciones');
        // console.log(listaSecciones);
        // console.log('indexSeccion');
        // console.log(indexSeccion);
        // console.log('indexPregunta');
        // console.log(indexPregunta);
        const userConfirmed = window.confirm('Confirma deshabilitar Pregunta Nro: ' + (parseInt(indexPregunta)) + ' Seccion Nro: ' + (parseInt(indexSeccion)));
        if (userConfirmed) {
            let newListaSecciones = [...listaSecciones];
            newListaSecciones[indexSeccion].preguntas[indexPregunta].query = INT_QUERY_DELETE;
            setListaSecciones(newListaSecciones);
        }
    };

    const handlePreguntaIcon = (indexSeccion, indexPregunta, event) => {
        try {
            // console.log('handlePreguntaIcon');
            // console.log('listaSecciones');
            // console.log(listaSecciones);
            // console.log('indexSeccion');
            // console.log(indexSeccion);
            // console.log('indexPregunta');
            // console.log(indexPregunta);
            // console.log('event');
            // console.log(event);
            let newListaSecciones = [...listaSecciones];
            if (event !== null) {
                newListaSecciones[indexSeccion].preguntas[indexPregunta].urlIcon = event.url;
                newListaSecciones[indexSeccion].preguntas[indexPregunta].valueUrlIcon = event.value;
            }
            if (event === null) {
                newListaSecciones[indexSeccion].preguntas[indexPregunta].urlIcon = null;
                newListaSecciones[indexSeccion].preguntas[indexPregunta].valueUrlIcon = null;
            }
            setListaSecciones(newListaSecciones);
            // console.log('newListaSecciones');
            // console.log(newListaSecciones);
        } catch (err) {
            console.log('handlePreguntaIcon err');
            console.log(err);
        }
    };

    const handleOpcionesRespuesta = async (indexSeccion, indexPregunta, event) => {
        try {
            // console.log('handleOpcionesRespuesta');
            // console.log('checklistSelected');
            // console.log(checklistSelected);
            // console.log('listaSecciones');
            // console.log(listaSecciones);
            // console.log('indexSeccion');
            // console.log(indexSeccion);
            // console.log('indexPregunta');
            // console.log(indexPregunta);
            // console.log('event');
            // console.log(event);
            // console.log('/////////**********////////');
            let newListaSecciones = [...listaSecciones];
            if (event !== null) {
                const existeHabilitado = newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas.find(e => e.value === event.value);
                const existeDeshabilitado = newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasDeshabilitadas.find(e => e.value === event.value);
                if (existeHabilitado === undefined && existeDeshabilitado === undefined) {
                    let objEnviar = {};
                    objEnviar.idChecklist = checklistSelected.id;
                    objEnviar.idSeccion = listaSecciones[indexSeccion].id;
                    objEnviar.idPreguntaSeccion = listaSecciones[indexSeccion].preguntas[indexPregunta].id;
                    objEnviar.idOpcionRespuesta = event.value;
                    const guardarOpcionRespuesta = await guardarOpcionRespuestaPregunta(objEnviar);
                    // console.log('guardarOpcionRespuesta');
                    // console.log(guardarOpcionRespuesta);
                    if (guardarOpcionRespuesta.creado === true) {
                        event.idBase = guardarOpcionRespuesta.id;
                        newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas.push(event);
                    }
                    if (guardarOpcionRespuesta.creado === false) {
                        alert('No se pudo agregar opcion respuesta, intente nuevamente');
                    }
                } else {
                    alert('Asegurese que su OPCION RESPUESTA SELECCIONADA no se encuentre en Opciones Respuesta Habilitadas/Deshabilitadas')
                }
            }
            setListaSecciones(newListaSecciones);
            // console.log('newListaSecciones');
            // console.log(newListaSecciones);
        } catch (err) {
            console.log('handleOpcionesRespuesta err');
            console.log(err);
        }
    };

    const eliminarOpcionRespuesta = async (indexSeccion, indexPregunta, indexOpcionRespuesta, element) => {
        try {
            // console.log('eliminarOpcionRespuesta');
            // console.log('listaSecciones');
            // console.log(listaSecciones);
            // console.log('indexSeccion');
            // console.log(indexSeccion);
            // console.log('indexPregunta');
            // console.log(indexPregunta);
            // console.log('indexOpcionRespuesta');
            // console.log(indexOpcionRespuesta);
            // console.log('element');
            // console.log(element);
            const userConfirmed = window.confirm('Confirma deshabilitar Opcion Respuesta: \n' + element.label);
            // console.log('userConfirmed');
            // console.log(userConfirmed);
            if (userConfirmed) {
                let newListaSecciones = [...listaSecciones];
                const deshabilitado = await deshabilitarOpcionRespuestaPregunta({ id: element.idBase });
                if (deshabilitado === true) {
                    newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas = newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas.filter(e => e.idBase !== element.idBase);
                    newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasDeshabilitadas.push(element);
                    setListaSecciones(newListaSecciones);
                    alert('Se deshabilito correctamente');
                }
                if (deshabilitado === false) {
                    alert('No se pudo deshabilitar opcion respuesta, intente nuevamente');
                }
                setListaSecciones(newListaSecciones);
            }
        } catch (err) {
            console.log('eliminarOpcionRespuesta err');
            console.log(err);
        }
    };

    const restaurarOpcionRespuesta = async (indexSeccion, indexPregunta, indexOpcionRespuesta, element) => {
        try {
            // console.log('restaurarOpcionRespuesta');
            // console.log('indexSeccion');
            // console.log(indexSeccion);
            // console.log('indexPregunta');
            // console.log(indexPregunta);
            // console.log('indexOpcionRespuesta');
            // console.log(indexOpcionRespuesta);
            // console.log('element');
            // console.log(element);
            const userConfirmed = window.confirm('Confirma restaurar Opcion Respuesta: \n' + element.label);
            // console.log('userConfirmed');
            // console.log(userConfirmed);
            if (userConfirmed) {
                let newListaSecciones = [...listaSecciones];
                const restaurar = await restaurarOpcionRespuestaPregunta({ id: element.idBase });
                if (restaurar === true) {
                    newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasDeshabilitadas = newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasDeshabilitadas.filter(e => e.idBase !== element.idBase);
                    newListaSecciones[indexSeccion].preguntas[indexPregunta].opcionesRespuestasHabilitadas.push(element);
                    setListaSecciones(newListaSecciones);
                    alert('Se restauro correctamente');
                }
                if (restaurar === false) {
                    alert('No se pudo restaurar opcion respuesta, intente nuevamente');
                }
                setListaSecciones(newListaSecciones);
            }
        } catch (err) {
            console.log('restaurarOpcionRespuesta err');
            console.log(err);
        }
    };

    const validarSeccion = () => {
        try {
            let error = "";
            for (let i = 0; i < listaSecciones.length; i++) {
                const element = listaSecciones[i];
                if (element.query === INT_QUERY_UPDATE) {
                    if (element.nombreSeccion === undefined || element.nombreSeccion === null || String(element.nombreSeccion).trim().length === 0) {
                        error = error + "Seccion Nro: " + (i).toString() + " No tiene NOMBRE SECCION \n";
                    }
                }
            }
            if (String(error).length === 0) {
                return true;
            }
            alert(error);
            return false;
        } catch (err) {
            console.log('validarSeccion err');
            console.log(err);
            return false;
        }
    };

    const validarPreguntas = () => {
        try {
            let error = "";
            for (let i = 0; i < listaSecciones.length; i++) {
                const element = listaSecciones[i];
                if (element.query === INT_QUERY_UPDATE && element.preguntas !== undefined) {
                    for (let k = 0; k < element.preguntas.length; k++) {
                        const pregunta = element.preguntas[k];
                        if (pregunta.query === INT_QUERY_UPDATE) {
                            if (pregunta.tituloPregunta === undefined || pregunta.tituloPregunta === null || String(pregunta.tituloPregunta).trim().length === 0) {
                                error = error + "Seccion Nro: " + (i).toString() + " Nro Pregunta: " + (k).toString() + " No tiene TITULO PREGUNTA/DESCRIPCION \n";
                            }
                            if (pregunta.idTipo === INT_PREGUNTA_SELECCION_MULTIPLE || pregunta.idTipo === INT_PREGUNTA_SELECCION_SIMPLE) {
                                if (pregunta.opcionesRespuestasHabilitadas.length === 0) {
                                    error = error + "Seccion Nro: " + (i).toString() + " Nro Pregunta: " + (k).toString() + " No tiene OPCIONES RESPUESTA \n";
                                }
                            }
                        }
                    }
                }
            }
            if (String(error).length === 0) {
                return true;
            }
            alert(error);
            return false;
        } catch (err) {
            console.log('validarPreguntas err');
            console.log(err);
            return false;
        }
    };

    const onPressGuardarPreguntas = async () => {
        try {
            // console.log('onPressGuardarPreguntas');
            // console.log(listaSecciones);
            const seccionesValidas = validarSeccion();
            if (seccionesValidas === false) {
                return;
            }
            const preguntasValidas = validarPreguntas();
            if (preguntasValidas === false) {
                return;
            }
            for (let indexSeccion = 0; indexSeccion < listaSecciones.length; indexSeccion++) {
                listaSecciones[indexSeccion].orden = indexSeccion;
                for (let indexPregunta = 0; indexPregunta < listaSecciones[indexSeccion].preguntas.length; indexPregunta++) {
                    listaSecciones[indexSeccion].preguntas[indexPregunta].orden = indexPregunta;
                }
            }
            let objEnviar = {};
            objEnviar.checklistSelected = checklistSelected;
            objEnviar.listaSecciones = listaSecciones;
            // objEnviar.manejoDefensivo = manejoDefensivo;
            const actualizado = await actualizarPreguntasChecklist(objEnviar);
            // console.log("actualizado");
            // console.log(actualizado);
            if (actualizado === true) {
                alert('Se actualizo Checklist correctamente');
                handleAtrasClick();
            }
            if (actualizado === false) {
                alert('No se pudo actualizar preguntas checklist, intente nuevamente');
            }
        } catch (err) {
            console.log('onPressGuardarPreguntas err');
            console.log(err);
        }
    };

    const render = () => {
        try {
            if (cargandoPage === true) {
                return cargandoCard();
            }
            if (noContent === true) {
                return (
                    <div className="flex justify-between pl-4 pr-4 items-center">
                        {noContentCard()}
                        <button
                            type="button"
                            className={ESTILO_BOTON_LISTAR_ATRAS}
                            onClick={handleAtrasClick}
                        >
                            ATRAS
                        </button>
                    </div>
                );
            }
            return (
                <div className="pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <div className="flex justify-center align-center">
                            <span>
                                PREGUNTAS CHECKLIST
                            </span>
                            <label
                                style={{
                                    paddingLeft: 10,
                                    fontWeight: 'bold',
                                }}
                            >
                                {checklistSelected.descripcion}
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="button"
                                className={ESTILO_BOTON_LISTAR_ATRAS}
                                onClick={handleAtrasClick}
                                onMouseEnter={() => setIsHoveredBackButton(true)}
                                onMouseLeave={() => setIsHoveredBackButton(false)}
                            >
                                {isHoveredBackButton ? (
                                    <ChevronsLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                                ) : (
                                    <ChevronLeft className="mr-2 transition-transform duration-500 ease-in-out" />
                                )}
                                ATRAS
                            </button>
                        </div>
                    </div>
                    <hr />
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <div className="flex items-center">
                                <span className="text-red-500 font-bold">*</span>
                                <label className="font-bold ml-1">NOMBRE CHECKLIST</label>
                            </div>
                            <input
                                id="nombre"
                                name="nombre"
                                type="text"
                                value={checklistSelected.nombre}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={255}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO CHECKLIST</label>
                            <Select
                                options={listaTipoChecklist}
                                isSearchable={true}
                                isClearable={true}
                                value={tipoChecklistSelected}
                                onChange={(e) => {
                                    setTipoChecklistSelected(e);
                                }}
                                placeholder={"Seleccione Tipo Checklist"}
                            />
                        </div>

                        {/* <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">AREA</label>
                            <Select
                                options={listaArea}
                                isSearchable={true}
                                isClearable={true}
                                value={areaSelected}
                                onChange={(e) => {
                                    setAreaSelected(e);
                                }}
                                placeholder={"Seleccione Area"}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">CIUDAD</label>
                            <Select
                                options={listaCiudad}
                                isSearchable={true}
                                isClearable={true}
                                value={ciudadSelected}
                                onChange={(e) => {
                                    setCiudadSelected(e);
                                }}
                                placeholder={"Seleccione Ciudad"}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO VEHICULO</label>
                            <Select
                                options={listaTipoVehiculo}
                                isSearchable={true}
                                isClearable={true}
                                value={tipoVehiculoSelected}
                                onChange={(e) => {
                                    setTipoVehiculoSelected(e);
                                }}
                                placeholder={"Seleccione Tipo Vehiculo"}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">MARCA VEHICULO</label>
                            <Select
                                options={listaMarcaVehiculo}
                                isSearchable={true}
                                isClearable={true}
                                value={marcaVehiculoSelected}
                                onChange={(e) => {
                                    setMarcaVehiculoSelected(e);
                                }}
                                placeholder={"Seleccione Marca Vehiculo"}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO CARROCERIA</label>
                            <Select
                                options={listaTipoCarroceria}
                                isSearchable={true}
                                isClearable={true}
                                value={tipoCarroceriaSelected}
                                onChange={(e) => {
                                    setTipoCarroceriaSelected(e);
                                }}
                                placeholder={"Seleccione Tipo Carroceria"}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
                            <label className="font-bold">TIPO COMBUSTIBLE</label>
                            <Select
                                options={listaTipoCombustible}
                                isSearchable={true}
                                isClearable={true}
                                value={tipoCombustibleSelected}
                                onChange={(e) => {
                                    setTipoCombustibleSelected(e);
                                }}
                                placeholder={"Seleccione Tipo Combustible"}
                            />
                        </div> */}
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4 items-center">
                        <div class="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6">
                            <label className="font-bold">NOTA</label>
                            <textarea
                                id="nota"
                                name="nota"
                                type="text"
                                value={checklistSelected.nota}
                                onChange={handleInputChange}
                                className={ESTILO_INPUT_GENERICO}
                                required
                                maxLength={2047}
                            />
                        </div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex justify-end">
                            <button
                                className={ESTILO_BOTON_MODAL_ACEPTAR}
                                onClick={onPressActualizar}
                            >
                                <RefreshCcw className={`mr-2 ${ESTILO_ROTATE_180}`} />
                                ACTUALIZAR
                            </button>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="flex flex-row items-center">
                        <h2 className="mr-2 font-bold">SECCION</h2>
                        <button
                            className={`${ESTILO_BOTON_GENERAL_ACEPTAR} flex items-center`}
                            title="AGREGAR SECCION"
                            onClick={() => { handleAddSection(); }}
                        >
                            <Plus className={`mr-2 ${ESTILO_ROTATE_180}`} />
                            NUEVA SECCION
                        </button>
                    </div>
                    <br />
                    <div>
                        {renderSections()}
                    </div>
                    <div className="flex flex-row justify-end mb-8">
                        <button
                            className={`${ESTILO_BOTON_MODAL_ACEPTAR} flex items-center`}
                            onClick={() => { onPressGuardarPreguntas(); }}
                            title="GUARDAR PREGUNTAS"
                        >
                            <Save className="mr-2" />
                            GUARDAR PREGUNTAS
                        </button>
                    </div>
                </div>
            );
        } catch (err) {
            console.log('render err dashboard');
            console.log(err);
            return (
                <div className="m-8" >
                    <label>{JSON.stringify(err)}</label>
                </div>
            );
        }
    };

    return render();
}
/*
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-6 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-6 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-6 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-6 bg-gray-500 p-4">Column 4</div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-500 p-4">Column 4</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-600 p-4">Column 5</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-4 bg-gray-700 p-4">Column 6</div>
                    </div>
                    <br />
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-200 p-4">Column 1</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-300 p-4">Column 2</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-400 p-4">Column 3</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-500 p-4">Column 4</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-600 p-4">Column 5</div>
                        <div class="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-2 bg-gray-700 p-4">Column 6</div>
                    </div>
                    */