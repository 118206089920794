import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/grid";
import { ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_LISTAR_NUEVO } from "../../../utils/const/estilos-const";
import ModalConfirmar from "../../../components/modal-confirmar";
import "../../../style/style-cargando.css";
import {
  INT_TIPO_USUARIO_ADMINISTRADOR_WEB,
} from "../../../utils/const/int-const";
import cargandoCard from "../../../components/cargando-card";
import sinAccesoCard from "../../../components/sin-acceso-card";
import { Settings } from "feather-icons-react/build/IconComponents";
import { sharedNextPage } from "../../../utils/shared-functions";
import { deshabilitarInputPieDePagina, guardarPieDePagina, obtenerListaPieDePagina } from "../../../api/api-pie-de-pagina";
import ModalPieDePagina from "../../../components/modal-pie-de-pagina";

const idTipoUsuario = parseInt(localStorage.getItem("tipoUsuarioChecklist"));
let accesoBotonNuevo = 'none';


export default function ListarPieDePagina() {
  const headers = [
    { key: "acciones", title: "ACCIONES" },
    { key: "id", title: "ID", visible: false, },
    { key: "idTipoChecklist", title: "ID TIPO CHECKLIST", visible: false, filter: true, editLabel: true, },
    { key: "tipoChecklist", title: "TIPO CHECKLIST", visible: true, filter: true, },
    { key: "nota", title: "NOTA", visible: true, filter: true, },
    { key: "fechaProceso", title: "FECHA PROCESO", visible: true, filter: true },
  ];
  const history = useHistory();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [listaOpcionesRespuesta, setListaOpcionesRespuesta] = useState([]);
  const [modalConfirmarDeshabilitarVisible, setModalConfirmarDeshabilitarVisible] = useState(false);
  const [modalPieDePaginaVisible, setModalPieDePaginaVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [opcionRespuestaSelected, setOpcionRespuestaSelected] = useState(null);
  // const [permisos, setPermisos] = useState(null);
  const [tieneAccesoAlModulo, setTieneAccesoAlModulo] = useState(true);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      setCargandoPage(true);
      /*let obj = {};
      obj.idMenuPrimerNivel = INT_ID_MODULO_CHECKLIST;
      obj.idMenuSegundoNivel = INT_ID_SEGUNDO_NIVEL_ADMINISTRACION_CHECKLIST;
      obj.idMenuTercerNivel = INT_ID_TERCER_NIVEL_CHECKLIST_OPCIONES_RESPUESTA;
      let obtenerPermisos = await obtenerPermisosPorNivelUsuarioWeb(obj);
      let permiso = obtenerPermisos[0];
      setPermisos(permiso);
      */
      let permiso = null;
      let lista = [];

      // if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      accesoBotonNuevo = '';
      setTieneAccesoAlModulo(true);
      lista = await obtenerListaPieDePagina();
      // }
      // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
      //   if (permiso.accesoAlModulo === TRUE) {
      //     setTieneAccesoAlModulo(true);
      //     lista = await obtenerListaTipoOpcionesRespuesta(false);
      //   }
      // }

      agregarAccionesALista(lista, permiso);
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
      setCargandoPage(false);
    }
  }

  const agregarAccionesALista = (lista, permiso) => {
    try {
      // console.log('agregarAccionesALista');
      // console.log(lista);
      if (lista.length === 0) {
        setListaOpcionesRespuesta(lista);
      }
      if (lista.length > 0) {
        const objModificar = {
          label: "Formulario",
          color: "blue",
          onClick: (param) => formularioPieDePagina(param),
        };
        // const objDeshabilitar = {
        //   label: "Deshabilitar",
        //   color: "red",
        //   onClick: (param) => cambiarEstado(param),
        // };
        // if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
        // if (permiso.nuevo === TRUE) {
        accesoBotonNuevo = '';
        // }
        // }
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          if (idTipoUsuario === INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            acciones.push(objModificar);
            // acciones.push(objDeshabilitar);
          }
          if (idTipoUsuario !== INT_TIPO_USUARIO_ADMINISTRADOR_WEB) {
            // if (permiso.modificar === TRUE) {
            acciones.push(objModificar);
            // }
            // if (permiso.eliminarDeshabilitar === TRUE) {
            // acciones.push(objDeshabilitar);
            // }
          }
          lista[i].acciones = acciones;
        }
        setListaOpcionesRespuesta(lista);
      }
    } catch (err) {
      console.log('agregarAccionesALista err');
      console.log(err);
    }
  }

  const formularioPieDePagina = (data) => {
    try {
      console.log("formularioPieDePagina");
      console.log(data);
      if (data.acciones !== undefined) {
        delete data.acciones;
      }
      let obj = {};
      obj.titulo = "MODIFICAR FORMULARIO PIE DE PAGINA";
      obj.params = data;
      // console.log('obj');
      // console.log(obj);
      sharedNextPage("pie-de-pagina", obj, history);
    } catch (err) {
      console.log("formularioPieDePagina err");
      console.log(err);
    }
  };

  const cambiarEstado = async (data) => {
    try {
      console.log("cambiarEstado");
      console.log(data);
      setOpcionRespuestaSelected(data);
      setModalConfirmarDeshabilitarVisible(true);
    } catch (err) {
      console.log("cambiarEstado err");
      console.log(err);
    }
  };

  const handleNuevoPieDePaginaClick = () => {
    // console.log("handleNuevoPieDePaginaClick");
    setFormData({
      ...formData,
      nota: "",
    });
    setModalPieDePaginaVisible(true);
  };

  const handleAtrasClick = () => {
    history.goBack();
  };

  const reset = async () => {
    setCargandoPage(true);
    iniciar();
  };

  const handleConfirmModal = async () => {
    setModalConfirmarDeshabilitarVisible(false);
    if (opcionRespuestaSelected == null) {
      return;
    }
    let objEnviar = {};
    objEnviar.id = opcionRespuestaSelected.id;
    if (opcionRespuestaSelected.id > 0) {
      // console.log('crear correo');
      const deshabilitado = await deshabilitarInputPieDePagina(objEnviar);
      // console.log('creado');
      // console.log(creado);
      if (deshabilitado === true) {
        alert('Se deshabilito correctamente');
        reset();
      }
      if (deshabilitado === false) {
        alert('No se pudo deshabilitar');
      }
    }
  };

  const onConfirmNuevoPieDePagina = async () => {
    setModalPieDePaginaVisible(false);
    // console.log("onConfirmNuevoPieDePagina");
    // console.log(formData);
    const resp = await guardarPieDePagina(formData);
    // console.log("resp");
    // console.log(resp);
    if (resp.error === false) {
      alert("Se creo correctamente");
      iniciar();
    }
    if (resp.error === true) {
      alert("No se pudo crear \n" + resp.message);
    }
  }

  const render = () => {
    try {
      // console.log('listar correo render');
      if (cargandoPage === true) {
        return cargandoCard();
      }
      if (tieneAccesoAlModulo === false) {
        return sinAccesoCard();
      }
      return (
        <div>
          <div
            style={{
              paddingTop: 16,
              paddingLeft: 16,
              paddingRight: 16,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <label
              style={{
                paddingLeft: 10,
                fontWeight: 'bold',
              }}
            >
              GESTIONAR PIE DE PAGINA
            </label>
            <div className="flex justify-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_NUEVO}
                onClick={handleNuevoPieDePaginaClick}
                style={{
                  // display: accesoBotonNuevo,
                }}
              >
                NUEVO
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                <Settings />
              </button>
            </div>
          </div>
          <Grid rows={listaOpcionesRespuesta} headers={headers} />
          <ModalConfirmar
            isOpen={modalConfirmarDeshabilitarVisible}
            onCancel={() => { setModalConfirmarDeshabilitarVisible(false); }}
            onConfirm={handleConfirmModal}
            message="¿Estás de deshabilitar Opcion Respuesta?"
          />
          <ModalPieDePagina
            isOpen={modalPieDePaginaVisible}
            onCancel={() => { setModalPieDePaginaVisible(false); }}
            formData={formData}
            setFormData={setFormData}
            onConfirm={onConfirmNuevoPieDePagina}
            title={"Nuevo Pie De Pagina"}
          />
        </div>
      );
    } catch (err) {
      console.log('ListarOpcionesRespuesta render err');
      console.log(err);
      return (
        <div><label>{JSON.stringify(err)}</label></div>
      );
    }
  }

  return render();
}
