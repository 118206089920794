import { useState, useEffect } from "react";
import Select from "react-select";
import { obtenerListaTipoChecklist } from "../api/tipo-checklist";

export default function ModalPieDePagina({ title, formData, setFormData, isOpen, onCancel, onConfirm }) {


    const [listaTipoChecklist, setListaTipoChecklist] = useState([]);
    const [tipoChecklistSelected, setTipoChecklistSelected] = useState(null);

    useEffect(() => {
        iniciar();
    }, []);

    useEffect(() => {
    }, [formData]);

    const iniciar = async () => {
        try {
            // console.log('formData');
            // console.log(formData);
            const listaTipoCheckL = await obtenerListaTipoChecklist(true);
            console.log('listaTipoCheckL');
            console.log(listaTipoCheckL);
            setListaTipoChecklist(listaTipoCheckL);
        } catch (err) {
            console.log('iniciar err');
            console.log(err);
        }
    };

    const charLimits = {
        nota: 128,
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= charLimits[name]) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        try {
            // console.log('validateForm');
            let message = "";
            if (tipoChecklistSelected === null) {
                message = message + "Debe seleccionar tipo checklist " + '\n';
            }
            if (message.length > 0) {
                alert(message);
                return;
            }
            formData.idTipoChecklist = tipoChecklistSelected.value;
            onConfirm(formData);
        } catch (err) {
            console.log('validateForm err');
            console.log(err);
        }
    };

    const cancelForm = () => {
        setFormData({
            ...formData,
            nota: "",
        });
        setTipoChecklistSelected(null);
        onCancel();
    }

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white sm:p-2 md:p-4 lg:p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-xl font-bold mb-4 text-gray-700">{title}</h2>
                        {/* <div className="rounded-lg w-1/2"> */}
                        {/* Inputs */}
                        <div className="mb-4">
                            <label className="block text-gray-600 font-semibold mb-1">Tipo Checklist<span className="text-red-500">*</span></label>
                            <Select
                                options={listaTipoChecklist}
                                isSearchable={true}
                                isClearable={true}
                                value={tipoChecklistSelected}
                                onChange={(event) => {
                                    setTipoChecklistSelected(event);
                                }}
                                placeholder={"Seleccion Tipo Checklist"}
                                className="w-full"
                                menuPortalTarget={document.body}  // Ensures dropdown is rendered outside
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} // Higher z-index
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-600 font-semibold mb-1">Nota</label>
                            <input
                                name="nota"
                                value={formData.nota}
                                onChange={handleInputChange}
                                placeholder={"Ingrese Nota"}
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                            />
                            <p className="text-sm text-gray-500 flex justify-end">{formData.nota.length}/{charLimits.nota}</p>
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={cancelForm}
                                className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500 transition duration-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={validateForm}
                                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
                            >
                                Acceptar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}
