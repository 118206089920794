import { FAILURE, POST, SUCCESS } from "../utils/const/http-const";
import { getHeaders } from "./utils";

export async function countUserName(obj) {
    try {
        let data = { error: true };
        const { code, message, body } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/count-username-usuario-web",
            {
                headers: getHeaders(false),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        console.log('code');
        console.log(code);
        console.log('body');
        console.log(body);
        if (code === SUCCESS) {
            data.error = false;
            data.body = body;
        }
        if (code === FAILURE) {
            data.error = true;
            data.message = message;
        }
        return data;
    } catch (err) {
        console.log("countUserName err");
        console.log(err);
        return { error: true, message: err };
    }
}

export async function registrarUsuario(obj) {
    try {
        let data = { error: true };
        const { code, message } = await fetch(
            "https://gestiondeflota.boltrack.net/apichecklistv2/api/usuario-web/crear-autousuario-usuario-web",
            {
                headers: getHeaders(false),
                method: POST,
                body: JSON.stringify(obj),
            }
        ).then((res) => res.json());
        // console.log('code');
        // console.log(code);
        // console.log('body');
        // console.log(body);
        if (code === SUCCESS) {
            data.error = false;
        }
        if (code === FAILURE) {
            data.error = true;
            data.message = message;
        }
        return data;
    } catch (err) {
        console.log("registrarUsuario err");
        console.log(err);
        return { error: true, message: err };
    }
}