import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cargandoCard from "../../../components/cargando-card";
import { ESTILO_BOTON_GENERAL_ACEPTAR, ESTILO_BOTON_LISTAR_ATRAS, ESTILO_BOTON_MODAL_ACEPTAR, ESTILO_INPUT_GENERICO, ESTILO_SECCION_BOTON_DOWN } from "../../../utils/const/estilos-const";
import {
  actualizarFormularioDetallePieDePagina,

  guardarDetallePieDePagina,



  obtenerDetalleListaPieDePagina,

} from "../../../api/api-pie-de-pagina";
import Select from "react-select";
import { SELECT_PIE_DE_PAGINA } from "./const_select_pie_de_pagina";
import { ArrowDownCircle, ArrowUpCircle, Save } from "feather-icons-react/build/IconComponents";
import SwitchComponent from "../../../components/switch";
import { PIE_DE_PAGINA } from "../../../utils/const/int-const";

export default function PieDePagina() {
  const history = useHistory();
  const location = useLocation();
  const [cargandoPage, setCargandoPage] = useState(true);
  const [inputFormularioSelected, setInputFormularioSelected] = useState(null);
  const [listaInputs, setListaInputs] = useState([]);
  const [idPieDePagina, setIdPieDePagina] = useState(null);

  useEffect(() => {
    iniciar();
  }, []);

  const iniciar = async () => {
    try {
      const data = location.state;
      // console.log("data");
      // console.log(data);
      setIdPieDePagina(data.params.id);
      let objEnviar = {};
      objEnviar.idPieDePagina = data.params.id;
      const infoFormularioChecklist = await obtenerDetalleListaPieDePagina(objEnviar);
      // console.log("infoFormularioChecklist");
      // console.log(infoFormularioChecklist);
      if (infoFormularioChecklist.length > 0) {
        let lista = [];
        for (let i = 0; i < infoFormularioChecklist.length; i++) {
          const element = infoFormularioChecklist[i];
          let obj = {
            id: element.id,
            idPieDePagina: data.id,
            idConcepto: element.idConcepto,
            // value: element.idConcepto,
            text: element.text,
            habilitado: element.habilitado,
            orden: element.orden,
          };
          lista.push(obj);
        }
        setListaInputs(lista);
      }
      setCargandoPage(false);
    } catch (err) {
      console.log('iniciar err');
      console.log(err);
    }
  }

  const handleAtrasClick = () => {
    history.goBack();
  };

  const handleAgregarClick = async () => {
    console.log('handleAgregarClick');
    if (inputFormularioSelected === null) {
      return;
    }
    // console.log('inputFormularioSelected');
    // console.log(inputFormularioSelected);
    // console.log('existe');
    // console.log(existe);
    let newInput = {
      idPieDePagina: idPieDePagina,
      idConcepto: inputFormularioSelected.value,
      text: "",
      orden: listaInputs.length,
      habilitado: true,
    };
    const guardoNuevo = await guardarDetallePieDePagina(newInput);
    // console.log('guardoNuevo');
    // console.log(guardoNuevo);
    if (guardoNuevo.error === false) {
      newInput.id = guardoNuevo.id;
      setListaInputs([...listaInputs, newInput]);
    }
    if (guardoNuevo.error === true) {
      alert("No se pudo agregar nuevo input");
    }
  };

  const handleInputChange = (event, indexInput) => {
    // console.log('handleInputChange');
    const { name, value } = event.target;
    // console.log(name);
    // console.log(value);
    // setListaInputs([ ...checklistSelected, [name]: value ]);
    setListaInputs((prevListInputs) =>
      prevListInputs.map((item, index) =>
        index === indexInput ? { ...item, [name]: value } : item
      )
    );
  };

  const renderListInputs = () => {
    try {
      // console.log('renderListInputs');
      // console.log(listaInputs);
      return (
        <>
          {listaInputs.map((input, index) => (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <div
                  style={{
                    width: '40%'
                  }}
                >
                  <li key={input.id}>
                    TIPO: {input.label}
                  </li>
                  {input.idConcepto === PIE_DE_PAGINA.INPUT_LABEL &&
                    <input
                      type="text"
                      name="text"
                      className={ESTILO_INPUT_GENERICO}
                      maxLength={128}
                      placeholder="EJEMPLO"
                      onChange={e => { handleInputChange(e, index); }}
                      value={input.text}
                    />
                  }
                  {input.idConcepto === PIE_DE_PAGINA.INPUT_LINEA_VACIA &&
                    <input
                      type="text"
                      name="text"
                      className={ESTILO_INPUT_GENERICO}
                      maxLength={128}
                      placeholder="Puede poner guion bajo aqui _____________"
                      onChange={e => { handleInputChange(e, index); }}
                      value={input.text}
                    />
                  }
                </div>
                <div
                  style={{
                    width: '10%'
                  }}
                >
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '30%',
                    alignItems: 'center',
                  }}
                >
                  {index > 0 && <button
                    type="button"
                    className={ESTILO_SECCION_BOTON_DOWN}
                    onClick={() => { moverSeccionUpClick(index); }}
                    title="MOVER IMPUT ARRIBA"
                  >
                    <ArrowUpCircle />
                  </button>}

                  {index < listaInputs.length - 1 &&
                    <button
                      type="button"
                      className={ESTILO_SECCION_BOTON_DOWN}
                      onClick={() => { moverSeccionDownClick(index); }}
                      title="MOVER IMPUT ABAJO"
                    >
                      <ArrowDownCircle />
                    </button>}

                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '20%',
                    alignItems: 'center',
                  }}
                >
                  <SwitchComponent isOn={input.habilitado} handleToggle={() => { handleToggle(input, index); }} />
                  <p className="ml-4" >{input.habilitado ? "ACTIVO" : "NO ACTIVO"}</p>
                </div>
              </div>
              <br />
              <hr />
              <br />
            </>
          ))}
        </>
      );
    } catch (err) {
      console.log('render err renderListInputs');
      console.log(err);
      return (
        <div className="m-8" >
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  const handleToggle = async (input, indexInput) => {
    try {
      // console.log('handleToggle');
      // console.log(input);
      // console.log(indexInput);
      setListaInputs((prevListInputs) =>
        prevListInputs.map((item, index) =>
          index === indexInput ? { ...item, habilitado: !item.habilitado } : item
        )
      );
    } catch (err) {
      console.log('handleToggle err');
      console.log(err);
    }
  };

  const moverSeccionUpClick = async (index) => {
    // console.log('moverSeccionUpClick');
    // console.log(index);
    if (index === 0) return; // No mover si es el primer elemento        
    // let objEnviar = {};
    // objEnviar.objetoA = listaInputs[index];
    // objEnviar.objetoB = listaInputs[index - 1];
    swapItems(index, index - 1);
  };

  const swapItems = (index1, index2) => {
    setListaInputs((prevListInputs) => {
      const newList = [...prevListInputs]; // Create a shallow copy of the array
      if (index1 >= 0 && index2 >= 0 && index1 < newList.length && index2 < newList.length) {
        [newList[index1], newList[index2]] = [newList[index2], newList[index1]]; // Swap elements
        newList[index1] = { ...newList[index1], orden: index1 };
        newList[index2] = { ...newList[index2], orden: index2 };
      }
      return newList;
    });
  };

  const moverSeccionDownClick = async (index) => {
    // console.log('moverSeccionDownClick');
    // console.log(index);
    if (index === listaInputs.length - 1) return; // No mover si es el último elemento
    // let objEnviar = {};
    // objEnviar.objetoA = listaInputs[index];
    // objEnviar.objetoB = listaInputs[index + 1];
    swapItems(index, index + 1);
  };

  const onPressGuardarFormuario = async () => {
    try {
      // console.log('onPressGuardarFormuario');
      // console.log('idPieDePagina', idPieDePagina);
      for (let i = 0; i < listaInputs.length; i++) {
        listaInputs[i].idPieDePagina = idPieDePagina;
      }
      // console.log('listaInputs', listaInputs);
      let countError = 0;
      for (let j = 0; j < listaInputs.length; j++) {
        const element = listaInputs[j];
        const resp = await actualizarFormularioDetallePieDePagina(element);
        // console.log('resp');
        // console.log(resp);
        if (resp.error === true) {
          countError = countError + 1;
        }
      }
      if (countError > 0) {
        alert("No se pudo actualizar todos los inputs, intente nuevamente");
      }
      if (countError === 0) {
        alert("Se actualizo correctamente");
        handleAtrasClick();
      }
    } catch (err) {
      console.log('onPressGuardarFormuario err');
      console.log(err);
    }
  };

  const render = () => {
    try {
      if (cargandoPage === true) {
        return cargandoCard();
      }
      return (
        <div className="pt-4 px-4 " >
          <div className="flex justify-between">
            <div className="flex justify-center align-center">
              <label
                style={{
                  paddingLeft: 10,
                  fontWeight: 'bold',
                }}
              >
                FORMULARIO CHECKLIST
              </label>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className={ESTILO_BOTON_LISTAR_ATRAS}
                onClick={handleAtrasClick}
              >
                ATRAS
              </button>
            </div>
          </div>
          <hr />
          <br />
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6">
              <label className="font-bold">SELECCIONE INPUT</label>
              <div
                className="flex"
              >
                <Select
                  options={SELECT_PIE_DE_PAGINA}
                  isSearchable={true}
                  isClearable={true}
                  value={inputFormularioSelected}
                  onChange={(e) => {
                    setInputFormularioSelected(e);
                  }}
                  placeholder={"Seleccione Input"}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: '350px' // Set your desired width here
                    })
                  }}
                />
                <div
                  className={ESTILO_BOTON_GENERAL_ACEPTAR}
                  style={{ marginRight: '16px' }}
                >
                  <button
                    type="button"
                    onClick={handleAgregarClick}
                  >
                    AGREGAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
          {renderListInputs()}
          <div className="flex flex-row justify-end mb-8">
            <button
              className={`${ESTILO_BOTON_MODAL_ACEPTAR} flex items-center`}
              onClick={() => { onPressGuardarFormuario(); }}
              title="GUARDAR PREGUNTAS"
            >
              <Save className="mr-2" />
              GUARDAR
            </button>
          </div>
        </div>
      );
    } catch (err) {
      console.log('render err ChecklistScreen');
      console.log(err);
      return (
        <div className="m-8" >
          <label>{JSON.stringify(err)}</label>
        </div>
      );
    }
  }

  return render();
}